import React, { useState, useImperativeHandle, forwardRef } from 'react';
import '../css/power-bar.css';

const PowerBar = forwardRef((props, ref) => {
  const [currentToggledIndex, setCurrentToggledIndex] = useState(5);
  const letters = ['P', 'O', 'W', 'E', 'R'];

  useImperativeHandle(ref, () => ({
    updateBar: (count) => {
      setCurrentToggledIndex(count);
    },
    getCurrentToggledIndex: () => {
      return currentToggledIndex;
    },
  }));

  return (
    <div className="power-container">
      {letters.map((letter, index) => (
        <div
          key={index}
          className={`letter ${index >= currentToggledIndex ? 'toggled' : ''}`}
        >
          {letter}
        </div>
      ))}
    </div>
  );
});

export default PowerBar;
