import React, { useState, useImperativeHandle, forwardRef } from 'react';
import HowToPlayModal from '../img/how-to-play-v2.png';
import '../css/TutorialModal.css';

const TutorialModal = forwardRef((props, ref) => {
    const [showTutorial, setShowTutorial] = useState(false);

    useImperativeHandle(ref, () => ({
        setShowTutorial,
    }));

    return (
        showTutorial && <div className='tutorialModal'>
        <img src={HowToPlayModal} alt="Tutorial on how to play"/>
    </div>
    );
});

export default TutorialModal;
