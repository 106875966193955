import React, { useImperativeHandle, forwardRef, useRef, useState } from 'react';
import '../css/HighScorePlane.css';  // Importing CSS
import PlaneImage from '../img/highscore-plane.png';

const HighScorePlane = forwardRef((props, ref) => {
  const [flyPlane, setDoFlyPlane] = useState(false);

  useImperativeHandle(ref, () => ({
    setDoFlyPlane,
    flyPlane
  }));

  return (
    <>
      {
        flyPlane && <img
          src={PlaneImage}
          alt="High Score Plane"
          className="highscore-plane"  // Applying class
        />
      }
    </>
  );
});

export default HighScorePlane;


