import '../css/Parallax.css';

import frame1 from "../img/bg-1-v2.jpg"
import frame2 from "../img/bg-2-v2.jpg";
import frame3 from "../img/bg-3-v2.jpg";

const ParallaxComponent = () => {
  const styles = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '500%',
    height: '100%',
    animation: `parallaxAnimation 20s infinite linear`,
  };

  const imageStyles = {
    display: 'block',
    width: '20%',
    height: '100%',
    float: 'left',
  };

  return (
    <div className="parallax-container">
      <div id="parallax-test" style={styles}>
        <img style={imageStyles} src={frame1}/>
        <img style={imageStyles} src={frame1}/>
        <img style={imageStyles} src={frame1}/>
        <img style={imageStyles} src={frame1}/>
      </div>
    </div>
  );
};

export default ParallaxComponent;
