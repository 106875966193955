import React, { useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import lottie from 'lottie-web';

function calculateCoinsEarned(score) {
    return Math.floor(score / 50);
  }

const CoinAnimation = forwardRef((props, ref) => {
  const animationRef = useRef();

  // Expose a method to trigger the animation
  useImperativeHandle(ref, () => ({
    playAnimation: (coinsEarned) => {
      const animationData = require('../lottie/coin_animation.json');
      const anim = lottie.loadAnimation({
        container: animationRef.current,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData: animationData,
        preserveAspectRatio: 'xMidYMid meet',
      });

      // Do set value to the coin component
      const coinCountElement = document.getElementById('coin-count');
      if (coinCountElement) {
        coinCountElement.textContent = `${Number(coinCountElement.textContent) + coinsEarned}`;
      }

      const handleComplete = () => {
        anim.destroy();
        let gameOverWrapper = document.getElementsByClassName("win-coins")[0];

        if (gameOverWrapper) {
          gameOverWrapper.style.display = "none";
        }
      };

      anim.addEventListener('complete', handleComplete);
    },
  }));

  return (
    <div className='win-coins'>
      <div ref={animationRef} />
    </div>
  );
});

export default CoinAnimation;
