import React, { useState, useEffect, useContext, useRef } from 'react';
import '../css/GameView.css';
import GameSpriteAnimation from './GameSpriteAnimation';
import FullScreenSprite from './FullScreenSprite';
import FoodAnimation from './FoodAnimation';
import PowerMode from './PowerMode';
import { AlexaContext } from '../utils/AlexaService';
import { useNavigate } from 'react-router-dom';
import BirdPooSound from '../audio/bird-poo-v2.mp3';
import BackingTrackAudio from '../audio/backing-track-v8.mp3';
import BiteAudio from '../audio/eat-chew.mp3';
import BiteAudio2 from '../audio/anna-bite.mp3';
import CartoonFlutter from '../audio/cartoon-flutter.mp3'
import Flapping from '../audio/flapping-v2.mp3';
import Splat1 from '../audio/splat-1.mp3';
import Splat2 from '../audio/splat-2.mp3';
import Splat3 from '../audio/splat-3.mp3';
import Splat4 from '../audio/splat-4.mp3';
import Splat5 from '../audio/splat-5.mp3';
import Splat6 from '../audio/poo-scream.mp3';
import Yummy1 from '../audio/yummy-1.mp3';
import Yummy2 from '../audio/yummy-2.mp3';
import Yummy3 from '../audio/yummy-3.mp3';
import Yummy4 from '../audio/yummy-4.mp3';
import Yummy5 from '../audio/yummy-5.mp3';
import Yummy6 from '../audio/yummy-6.mp3';
import Yummy7 from '../audio/yummy-7.mp3';
import MegaSplat from '../audio/mega-poo-scream-v3.mp3';
import Screech1 from '../audio/screech-1.mp3';
import Screech2 from '../audio/screech-2.mp3';
import Screech3 from '../audio/screech-3.mp3';
import Screech4 from '../audio/screech-4.mp3';
import Falling from '../audio/falling-v7.mp3';
import idleGif from '../img/gifs/idle.gif';
import eatGif from '../img/gifs/eat.gif';
import smallEat from '../img/gifs/eat-small-v2.gif';
import flyUpGif from '../img/gifs/fly-up.gif';
import flyDownGif from '../img/gifs/fly-down.gif';
import explodeGif from '../img/gifs/explode.gif';
import FoodMap from '../utils/FX';
import EnergyCountdown from './Countdown';
import AudioUtil from '../utils/audioFunctions';
import PowerModeFx from '../audio/power-mode.mp3';
import Timer from './Timer';
import SimpleFart from '../audio/simple-fart.mp3';
import FullyExplode from '../audio/explode-v8.mp3';
import FinalEnergy from '../audio/eat-food-alarm-quiet.mp3';
import HighScorePlane from './HighscorePlane';

import PlusImage from './PlusImage';

import PowerBar from './PowerBar';
import HighScoreFx from '../audio/highscore-v2.mp3';
import FallingCakes from './FallingCakes';
import SpeechBubble from './SpeechBubble';
import PoopContainer from './PoopContainer';

import PressableButton from './PressableButton';
import Plus10 from '../img/plus-10.png';
import Plus30 from '../img/plus-30.png';


import EnergyTimer from './EnergyTimer';

import MultipleFoodAnimation from './MultipleFoodAnimation';

import DistanceCounter from './DistanceCounter';

const POWER_MODE_THRESHOLD = 5;
function getRandomElement(arr) {
  // Generate a random index based on the array length
  const randomIndex = Math.floor(Math.random() * arr.length);
  return arr[randomIndex];
}
const doEnergyWarning = (doWarning) => {
  const energyBar = document.getElementById("energy-bar-id");
  if (doWarning) {
    energyBar.className = "energy-bar pulse";
  } else {
    energyBar.className = "energy-bar";
  }
}

function stopEnergyAudio() {
  const energyEl = document.getElementById("final-energy-countdown");
  if (energyEl.currentTime > 0) {
    energyEl.pause();
    energyEl.currentTime = 0;
  }
}

function stopExplodeAudio() {
  const audioEl = document.getElementById("fully-explode");
  
  if (audioEl.currentTime > 0) {
    audioEl.pause();
    audioEl.currentTime = 0;
  }
}

const GameView = () => {
  const { message, setMessage, sendMessage, requestMicrophoneOpen, seagullData, topScores} = useContext(AlexaContext);
  // Rarely called props, so we dont care much
  const [ranOutOfEnergy, setRanOutOfEnergy] = useState(false);
  const [exploded, setExploded] = useState(false);
  const poopTimer = useRef(0);
  const energyCountdown = useRef(55);
  const distance = useRef(0);
  const energyCountdownSpeed = useRef(0.5);

  // const [energyWarning, doEnergyWarning] = useState(false);
  const cleanupFuncsRef = useRef([]);
  // const [animateFood, setAnimateFood] = useState(false);
  // const [resetAnimation, setResetAnimation] = useState(false);
  // const [foodImage, setFoodImage] = useState(RandomFood);// Just defaulting to this for now
  const utteranceCount = useRef(0);
  const gameOverReason = useRef("");
  const explosionTimeOut = useRef(null);

  const navigate = useNavigate();
  const setDoFastBackground = (doFast) => {
    setPowerModeRef(doFast);

    const element = document.getElementById("parallax-test");
    if (doFast) {
      element.style.animation = 'parallaxAnimation 5s infinite linear';
    } else {
      element.style.animation = 'parallaxAnimation 20s infinite linear';
    }
  };

  const powerBarRef = useRef(null);
  const speechBubbleRef = useRef(null);
  const poopAnimRef = useRef(null);
  const poopWarningRef = useRef(null);
  const gifRef = useRef(null);
  const fallingCakeRef = useRef(null);
  const highscoreRef = useRef(null);
  const poopTimerRef = useRef(null);
  const powerModeRef = useRef(null);
  const energyTimerRef = useRef(null);
  const multipleFoodRef = useRef(null);
  const distanceCounterRef = useRef(null);
  const buttonRef = useRef(null);
  const plusRef = useRef(null);


  const showPulseImage = (pulseImage) => {
    plusRef.current.triggerPlusImage(pulseImage);
  }


  const toggleEatButton = () => {
    buttonRef.current.disableEat();
  }

  const toggleReleaseButton = () => {
    buttonRef.current.disableRelease();
  }

  const getIsLastSeconds = () => {
    return energyTimerRef.current.isLastSeconds;
  }

  const setIsLastSeconds = () => {
    energyTimerRef.current.setIsLastSeconds(false);
  }

  const doRiskyEater = () => {
    distanceCounterRef.current.setRiskyEater(true);
  }

  const showAllFoodAnimation = (food) => {
    multipleFoodRef.current.doAllFoodAnimations(food);
  }

  const setPowerModeRef = (val) => {
    powerModeRef.current.setIsVisible(val)
  }

  const setPoopWarning = (setWarning = false) => {
    poopTimerRef.current.setPoopWarning(setWarning);
  }

  const toggleThePower = (amount) => {
    powerBarRef.current.updateBar(amount); // Call the method on the child to update its state
  };

  const getPowerToggle = () => {
    return powerBarRef.current.getCurrentToggledIndex();
  }

  const triggerSpeechBubble = () => {
    speechBubbleRef.current.triggerSpeechBubble();
  }

  const triggerPoop = (shouldTrigger) => {
    if (shouldTrigger) {
      poopAnimRef.current.triggerAnimation();
    } else {
      poopAnimRef.current.hideAnimation();
    }
  }

  const setTimerPaused = (isPaused) => {
    if (isPaused) {
      energyTimerRef.current.setTimerIsPaused(true);
    } else {
      energyTimerRef.current.setTimerIsPaused(false);
    }
  }

  const triggerPoopWarning = (shouldTrigger) => {
    if (shouldTrigger) {
      poopWarningRef.current.triggerAnimation();
    } else {
      poopWarningRef.current.hideAnimation();
    }
  }

  const dropTheCake = () => {
    fallingCakeRef.current.dropTheCake();
  }

  const checkPoopWarning = () => {
    return poopWarningRef?.current?.poopAlarmIsShowing();
  }

  const setGifProps = (props) => {
    gifRef.current.setGifProps(props);
  }

  const doHighScorePlane = () => {
    highscoreRef.current.setDoFlyPlane(true);
  }

  const planeIsFlying = () => {
    return highscoreRef.current.flyPlane;
  }

  function cleanUpRefs() {
    if (cleanupFuncsRef.current.length) {
      cleanupFuncsRef.current.forEach((func) => {
        func()
      });
      cleanupFuncsRef.current = [];
    }
  }



  useEffect(() => {
    const audioEl = document.getElementById("audio-element");
    audioEl.play().catch((err) => console.log(err));

    const flutterAudio = new Audio(CartoonFlutter);

    const {play: playFlutter, cleanup: cleanupFlutter} = AudioUtil.playAudio(flutterAudio);

    playFlutter();

    cleanupFuncsRef.current.push(
      // () => clearTimeout(startIdleTimeout),
      () => cleanupFlutter()
    )

    return () => {
      cleanUpRefs(); // This gets any remaining cleanups we missed
      cleanupFlutter();
      audioEl.pause(); // Pause the audio
      audioEl.currentTime = 0; // Reset the audio to the beginning
    };

  }, []); // Empty dependency array to run this effect only once


  useEffect(() => {
    if (ranOutOfEnergy) {
      
      setDoFastBackground(false);

      if (explosionTimeOut.current) {
        clearTimeout(explosionTimeOut.current);
      }
      // setRanOutOfEnergy(false);
      gameOverReason.current = "energy";

      stopExplodeAudio();
      stopEnergyAudio();
    
      const fallingAudio = new Audio(Falling);
      const {play: playFalling, cleanup: cleanupFalling} = AudioUtil.playAudio(fallingAudio);
    
      playFalling(); 
      
      setTimerPaused(true);
    
      setGifProps({
        img: idleGif,
        animation: 'fallOff',
      });
    
      // Game over logics
      const timeoutId = setTimeout(() => {
        setMessage({ ...message, navigateTo: null, isGameOver: true, command: null })
        navigate('/', { state: { score: distance.current, reason: gameOverReason.current } });
      }, 2000);
    
      return () => {
        cleanupFalling();
        clearTimeout(timeoutId);
      }

    }
  }, [ranOutOfEnergy]);

  useEffect(() => {
    if (exploded) {

      cleanUpRefs();

      setDoFastBackground(false);
      if (explosionTimeOut.current) {
        clearTimeout(explosionTimeOut.current);
      }
      gameOverReason.current = "poop";

      triggerPoopWarning(false);
      setPoopWarning(false);
      setTimerPaused(true); // Pause the energy countdown
      setGifProps({
        img: explodeGif,
        animation: 'idle',
      });
    
      const audioEl = document.getElementById("final-energy-countdown");
      if (audioEl.currentTime > 0) {
        audioEl.pause();
        audioEl.currentTime = 0;
      }
    
      const timeoutId = setTimeout(() => {
        setMessage({ ...message, navigateTo: null, isGameOver: true, command: null })
        navigate('/', { state: { score: distance.current, reason: gameOverReason.current } });
      }, 1700);

      return () => {
        if (explosionTimeOut.current) {
          clearTimeout(explosionTimeOut.current);
        }
        clearTimeout(timeoutId);
        audioEl.pause();
        audioEl.currentTime = 0;
      }
    }

  }, [exploded]);

  useEffect(() => {
    if (message && message.command === "reprompt") {
      const micOpenTimeOut = setTimeout(() => {
        requestMicrophoneOpen();
      }, 700)

      return () => {
        clearTimeout(micOpenTimeOut);
      }
    }

    if (message && message.command === 'eat') {
      if (ranOutOfEnergy || exploded) {
        sendMessage.current(
          {
            setState: "FORCE_SEAGULL_GAME_OVER"
          }
        );
      } else {
        cleanUpRefs();

        let micCleanup;

        if (message.type === "quick") {
          const micOpenTimeOut = setTimeout(() => {
            requestMicrophoneOpen();
          }, 700)
    
          micCleanup = () => {
            clearTimeout(micOpenTimeOut);
          }
        }

        if (getIsLastSeconds()) {
          setIsLastSeconds(false);
        }

        if (message.isTouch) {
          showPulseImage(Plus10);
        } else {
          showPulseImage(Plus30);
        }

        toggleEatButton();
        setTimerPaused(true); 

        if (seagullData.isHighPerformance) {
          setGifProps({
            img: flyDownGif,
            animation: 'flyDown',
          });
        } else {
          setGifProps({
            img: idleGif,
            animation: 'flyDown',
          });
        }
        
  
      const cleanupTasks = [];

      if (micCleanup) cleanupTasks.push(micCleanup);

  // DOnt allow to eat if exploded or out of energy
      if (!exploded && !ranOutOfEnergy) {

            // Don't want to increase the utterance account on poop alarm. So we always get the POWER MODE NEXT
           if (!message.isTouch) {
             utteranceCount.current ++;
           }

            stopEnergyAudio();
            
            if (energyCountdown.current <= 25) {
              // Now we trigger the RISKY EATER message in the countdown
              doRiskyEater();
              doEnergyWarning(false);
            }

            let diff = 100 - energyCountdown.current;

            let incr;

            if (message.isTouch) {
              incr = diff > 10 ? 10 : diff;
            } else {
              incr = diff > 30 ? 30 : diff;
            }
            energyCountdown.current += incr;
            // Let's do it so it falls at a quicker rate as time goes on

            if (message.isTouch) {
              // We do it quicker if its touch
              energyCountdownSpeed.current = energyCountdownSpeed.current * 0.8;
            } else {
              energyCountdownSpeed.current = energyCountdownSpeed.current * 0.8;
            }

              let stop1 = document.querySelector('#stop1');
              stop1.setAttribute('offset', `${energyCountdown.current}%`);
              // stop2.setAttribute('offset', `${energyCountdown.current}%`);
              if (energyCountdown.current >= 70 && stop1.style.stopColor != 'yellow') {
                  stop1.style.stopColor = 'yellow';
              } else if (energyCountdown.current < 70 && energyCountdown.current >= 35 && stop1.style.stopColor != 'orange') {
                  stop1.style.stopColor = 'orange';
              } else if (energyCountdown.current < 35 && stop1.style.stopColor != 'red') {
                  stop1.style.stopColor = 'red';
              }  



            const randomYummy = getRandomElement(
              [
                Yummy1, 
                Yummy2,
                Yummy3,
                Yummy4,
                Yummy5,
                Yummy6,
                Yummy7
              ]
            );
            const yummyAudio = new Audio(randomYummy);
            const randomScreech = getRandomElement([Screech1, Screech2, Screech3, Screech4]);
            const screechAudio = new Audio(randomScreech);

            let randomFood = getRandomElement(FoodMap);

            if (seagullData.isHighPerformance) {
              showAllFoodAnimation(randomFood.img);
            } 

            const commentAudio = new Audio(randomFood.audio);
            const biteAudio = new Audio(getRandomElement([
              BiteAudio,
              BiteAudio2,
            ]));
            const flappingAudio = new Audio(Flapping);

            const { play: playFlappingAudio, cleanup: cleanupFlappingAudio } = AudioUtil.playAudio(flappingAudio);
            const { play: playBite, cleanup: cleanupBite } = AudioUtil.playAudio(biteAudio);
            const { play: playYummy, cleanup: cleanupYummy } = AudioUtil.playAudio(yummyAudio);
            const { play: playComment, cleanup: cleanupComment } = AudioUtil.playAudio(commentAudio);
            const { play: playScreech, cleanup: cleanupScreech } = AudioUtil.playAudio(screechAudio);

            const doPowerMode = utteranceCount.current % POWER_MODE_THRESHOLD === 0 && utteranceCount.current != 0;

            const poopWarning = checkPoopWarning();

            poopTimer.current = Math.min(poopTimer.current + 34, 100);

            let poopStop1 = document.querySelector('#poopStop1');
            let poopStop2 = document.querySelector('#poopStop3');

            // Adjust the offset and color of the stop elements based on the new energyCountdown value
            poopStop1.setAttribute('offset', `${poopTimer.current}%`);
            poopStop2.setAttribute('offset', `${poopTimer.current}%`);

            const startPoopWarning = poopTimer.current >= 100 && !poopWarning;
            // Now set this and play a sound effect

            if (startPoopWarning) {
                triggerPoopWarning(true);
                setPoopWarning(true);

                // We can Start a countdown
                const explodeAudioEl = document.getElementById("fully-explode");
                explodeAudioEl.play().catch((err) => console.log(err));

                const timeout = setTimeout(() => {
                    if (poopTimer.current >= 100) {
                      setExploded(true)
                    }
              }, 10800);

              explosionTimeOut.current = timeout;
            }

            if (doPowerMode) {
              const powerModeAudio = new Audio(PowerModeFx);
              const { play: playPowerMode, cleanup: cleanupPowerMode } = AudioUtil.playAudio(powerModeAudio);
              toggleThePower(5); // Reset this.
              playPowerMode();
              setDoFastBackground(true);

              sendMessage.current(
                {
                  doPowerMode: true
                }
              );

              const micOpenTimeOut = setTimeout(() => {
                requestMicrophoneOpen();
              }, 700)


              // We dont want to run this when there is an existing poop timer.
              if (poopTimer.current < 100 || !explosionTimeOut.current) {
                const timeout = setTimeout(() => {
                      setExploded(true)
                }, 7000);
  
                explosionTimeOut.current = timeout;
              }
              
              cleanupFuncsRef.current.push(
                () => cleanupPowerMode(),
                () => clearTimeout(micOpenTimeOut)
              );
            } else {
              // Dont toggle the power if its touch
              if (!message.isTouch) {
                const currPower = getPowerToggle();
                if (currPower <= 5) {
                  toggleThePower(currPower - 1);
                }
              }
            }


            const timeouts = [
              [1000, () => {
                playScreech()
                // Second chance to pause this.
                stopEnergyAudio();
                
              }],
              [2725, () => {
                playBite()
                // Goes to 100 now
                // This unpauses the timer.
                const extraTimeout = setTimeout(() => {
                  setTimerPaused(false);
                }, 2000);
                cleanupFuncsRef.current.push(() => clearTimeout(extraTimeout));

              }],
              [1275, () => {

                if (seagullData.isHighPerformance) {
                  setGifProps({ img: flyUpGif, animation: 'flyBack', });
                } else {
                  setGifProps({ img: idleGif, animation: 'flyBack', });
                }
                triggerSpeechBubble();
              }],
              [2325, () => {

                if (seagullData.isHighPerformance) {
                  setGifProps({ img: eatGif, animation: 'idle', });
                } else {
                  setGifProps({ img: smallEat, animation: 'idle', });
                }

              }],
              [3525, () => {
                setGifProps({ img: idleGif, animation: 'idle', });
              }],
            ];

            timeouts.forEach(([delay, func]) => {
              const timeoutId = setTimeout(func, delay);
              cleanupTasks.push(() => clearTimeout(timeoutId));
            });

            biteAudio.addEventListener('ended', playYummy);
            screechAudio.addEventListener('ended', playComment);

            playFlappingAudio();

              cleanupFuncsRef.current.push(
                () => cleanupFlappingAudio(),
                () => cleanupBite(),
                () => cleanupScreech(),
                () => cleanupYummy(),
                () => cleanupComment(),
                () => biteAudio.removeEventListener('ended', playYummy),
                () => screechAudio.removeEventListener('ended', playComment),
                ...cleanupTasks,
              );

              return () => {
                cleanupFlappingAudio();
                cleanupBite();
                cleanupScreech();
                cleanupYummy();
                cleanupComment();
                biteAudio.removeEventListener('ended', playYummy);
                screechAudio.removeEventListener('ended', playComment);
                cleanupTasks.forEach((task) => task());
              }
      }
      }
    }

    if (message && message.command === 'poop') {
      if (exploded || ranOutOfEnergy) {
        sendMessage.current(
          {
            setState: "FORCE_SEAGULL_GAME_OVER",
          }
        );
      } else {

        if (!message.isTouch) {
          utteranceCount.current++;
        }
      
        if (poopTimer.current > 0) {
          cleanUpRefs();
    
          let micCleanup;
          if (message.type === "quick") {
            const micOpenTimeOut = setTimeout(() => {
              requestMicrophoneOpen();
            }, 700)
      
            micCleanup = () => {
              clearTimeout(micOpenTimeOut);
            }
          }

          const doPowerMode = utteranceCount.current % POWER_MODE_THRESHOLD === 0 && utteranceCount.current != 0;
          setTimerPaused(true);
          triggerPoop(true);
          toggleReleaseButton();
          triggerPoopWarning(false);
          setPoopWarning(false);
          setGifProps(
            {
              img: idleGif,
              animation: 'idle',
            }
          )
          stopExplodeAudio();
    
          if (explosionTimeOut.current) {
            clearTimeout(explosionTimeOut.current);
          }
      
    
          if (doPowerMode) {
            const powerModeAudio = new Audio(PowerModeFx);
            const { play: playPowerMode, cleanup: cleanupPowerMode } = AudioUtil.playAudio(powerModeAudio);
            toggleThePower(5); // Reset this.
            playPowerMode();
            setDoFastBackground(true);
            sendMessage.current(
              {
                doPowerMode: true
              }
            );


            // If there is existing poop timer, we wont run this.
            // We give them 7 secs to respond to this

            if (poopTimer.current < 100 || !explosionTimeOut.current) {
              const timeout = setTimeout(() => {
                    setExploded(true)
              }, 7000);

              explosionTimeOut.current = timeout;
            }


            
            cleanupFuncsRef.current.push(
              () => cleanupPowerMode(),
              // () => clearTimeout(micOpenTimeOut),
            );
          } else {
            if (!message.isTouch) {
              const currPower = getPowerToggle();
              if (currPower <= 5) {
                toggleThePower(currPower - 1);
              }
            }
          }
      
          const splat = getRandomElement([Splat1, Splat2, Splat3, Splat4, Splat5, Splat6]);
      
          const birdPoopAudio = new Audio(BirdPooSound);
          let splatAudio = new Audio(splat);
      
          const { play: playBirdPoop, cleanup: cleanupBirdPoop } = AudioUtil.playAudio(birdPoopAudio);
          const { play: playSplat, cleanup: cleanupSplatAudio } = AudioUtil.playAudio(splatAudio);
    
    
          poopTimer.current = Math.max(poopTimer.current - 34, 0);
      
          let stop1 = document.querySelector('#poopStop1');
          let stop2 = document.querySelector('#poopStop3');
    
          // Adjust the offset and color of the stop elements based on the new energyCountdown value
          stop1.setAttribute('offset', `${poopTimer.current}%`);
          stop2.setAttribute('offset', `${poopTimer.current}%`);
    
    
          playBirdPoop();
      
          let timeout1 = setTimeout(() => {
            triggerPoop(false);
            playSplat();
    
            if (!doPowerMode) {

              setTimerPaused(false);
            }
            // Sometimes because we're slicing the animations, the idle gets lost
          }, 1600);
      
          // cleanupFuncsRef.current.push(
          //   () => clearTimeout(timeout1),
          //   () => cleanupBirdPoop(),
          //   () => cleanupSplatAudio()
          // );
    
          return () => {
            clearTimeout(timeout1);
            cleanupBirdPoop();
            cleanupSplatAudio();

            if (micCleanup) {
              micCleanup();
            }
          }
    
        } else {
          // Can't poop. 
          const simpleFartAudio = new Audio(SimpleFart);
          const {play: playSimpleFart, cleanup: cleanUpSimpleFart} = AudioUtil.playAudio(simpleFartAudio);
          
          playSimpleFart();
          // setTimerPaused(true);
          cleanupFuncsRef.current.push(
            () => cleanUpSimpleFart(),
          );
    
          return () => {
            cleanUpSimpleFart();
          }
        }
      }
    }

    if (message && message.command === "power") {
      // We have this check to force back the pwoer mode state incase it gets set when the user is about to game over.
      if (exploded || ranOutOfEnergy) {
        sendMessage.current(
          {
            setState: "FORCE_SEAGULL_GAME_OVER",
          }
        );
      } else {
        cleanUpRefs();

        if (explosionTimeOut.current) {
          clearTimeout(explosionTimeOut.current);
        }
      
        let micCleanup;
        if (message.type === "quick") {
          const micOpenTimeOut = setTimeout(() => {
            requestMicrophoneOpen();
          }, 700)
      
          micCleanup = () => {
            clearTimeout(micOpenTimeOut);
          }
        }

        utteranceCount.current = 0;
        energyCountdown.current = 100;
        poopTimer.current = 0;
      
        setDoFastBackground(false);
        setPoopWarning(false);
        triggerPoopWarning(false);
        stopExplodeAudio();
        stopEnergyAudio();

        if (getIsLastSeconds()) {
          setIsLastSeconds(false);
        }

        setTimerPaused(true);
      
        let poopStop1 = document.querySelector('#poopStop1');
        let poopStop2 = document.querySelector('#poopStop3');
      
        // Adjust the offset and color of the stop elements based on the new energyCountdown value
        poopStop1.setAttribute('offset', `${poopTimer.current}%`);
        poopStop2.setAttribute('offset', `${poopTimer.current}%`);
      
      
        // Remove this pulse
        let stop1 = document.querySelector('#stop1');
        // let stop2 = document.querySelector('#stop2');
        // Adjust the offset and color of the stop elements based on the new energyCountdown value
        stop1.setAttribute('offset', `${energyCountdown.current}%`);
        // stop2.setAttribute('offset', `${energyCountdown.current}%`);
        stop1.style.stopColor = 'yellow';
      
        let splatAudio = new Audio(MegaSplat);
      
        const { play: playMegaSplat, cleanup: cleanupSplatAudio } = AudioUtil.playAudio(splatAudio);
      
        playMegaSplat();
  
        // We need to do the lighweight animation
        if (seagullData.isHighPerformance) {
          dropTheCake();
        } 

        triggerPoop(true);  

        let timeout1 = setTimeout(() => {
          triggerPoop(false);
          setTimerPaused(false);
          setGifProps({ img: idleGif, animation: 'idle', });
        }, 1600);

        return () => {
          clearInterval(timeout1);
          cleanupSplatAudio();
          if (micCleanup) {
            micCleanup();
          }
        }
    

      }
    }

  }, [message]);

  return (
    <div>

      <audio id="audio-element" src={BackingTrackAudio} preload="auto" loop></audio>
      <audio id="fully-explode" src={FullyExplode} preload="auto"></audio>
      <audio id="final-energy-countdown" src={FinalEnergy} preload="auto"></audio>


      <FullScreenSprite ref={poopAnimRef} isHighPerformance={seagullData.isHighPerformance}/>
      <EnergyTimer
        doEnergyWarning={doEnergyWarning}
        setRanOutOfEnergy={setRanOutOfEnergy}
        energyCountdown={energyCountdown}
        energyCountdownSpeed={energyCountdownSpeed}
        ref={energyTimerRef}
        locale={seagullData.locale}
      />
      <div className="game-view">
      <HighScorePlane ref={highscoreRef}/>

       {
        seagullData.isHighPerformance && <MultipleFoodAnimation ref={multipleFoodRef} />
       } 

        <GameSpriteAnimation ref={gifRef}/>

        <DistanceCounter
          ref={distanceCounterRef}
          distance={distance}
          topScores={topScores}
          doHighScorePlane={doHighScorePlane} // function from the previous step
          planeIsFlying={planeIsFlying}
        />

          <div id="energy-bar-container">
            <div id="energy-bar-id" className="energy-bar" >
              <EnergyCountdown energy={energyCountdown.current}/>
            </div>
              <div className="label-container">
              <p id="alexa-text">Alexa</p>
              <p id="eat-food-text">Eat Food!</p>
            </div>
          
          </div>

        <PlusImage ref={plusRef}/>
          
        <PoopContainer ref={poopTimerRef}/>
        <SpeechBubble ref={speechBubbleRef}/>
        <PowerBar ref={powerBarRef}/>
        <Timer ref={poopWarningRef}/>
        <PowerMode ref={powerModeRef}/>
        <FallingCakes ref={fallingCakeRef}/>
        <PressableButton 
        ref={buttonRef}
        />
      </div>
    </div>
  );
};

export default GameView;
