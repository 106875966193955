import React, { useState, useImperativeHandle, forwardRef } from 'react';
import '../css/GameSpriteAnimation.css';
import idleGif from '../img/gifs/idle.gif';

const GameSpriteAnimation = forwardRef((props, ref) => {
  const [gifProps, setGifProps] = useState({
    img: idleGif,
    animation: 'idle',
  });
  const { img, animation } = gifProps;

  useImperativeHandle(ref, () => ({
    setGifProps,
    gifProps, 
  }), []);

  return (
    <img
      className={`game-gif ${animation}`}
      src={img}
      alt="bird sprite"
    />

  );
});

export default GameSpriteAnimation;