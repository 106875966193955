import { useContext, useState, useRef, useEffect } from 'react';
import '../css/NavBar.css';

function NavBar() {
  const seagullData = {};


  const coins = seagullData?.coins || 0;
  const isSubscriber = seagullData?.isSubscriber;
  const rankImage = `team-icons/electric-1st.png`;

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleClick = (item) => {
    console.log(`${item} clicked!`);
    if (item === 'Burger') setIsMenuOpen(prev => !prev);
  };

  const navigateToLobby = () => {
    window.sendMessage(
      {
        switchView: "lobby"
      }
    );
  }

  const navigateToMonthly = () => {
    window.sendMessage(
      {
        switchView: "monthly"
      }
    );
  }

  const navigateToShare = () => {
    window.sendMessage(
      {
        switchView: "share"
      }
    );
  }

  const navigateToRanking = () => {
    window.sendMessage(
      {
        switchView: "ranking"
      }
    );
  }

  const navigateToSubscribe = () => {
    window.sendMessage(
      {
        switchView: "subscribe"
      }
    );
  }

  const navigateToCoinsEarned = () => {
    window.sendMessage(
      {
        switchView: "coinsEarned"
      }
    );
  }

  return (
    <nav className="nav">
      <div className="nav-section burger">
        <img className="item burger" src={require("../img/burger.png")} onClick={() => handleClick('Burger')} />
        {isMenuOpen &&
          <div className="dropdown">
            <div className="panel-container">
              <img src={require("../img/panel.png")} className="panel" />
              <div className="button-container">
                <img className="dropdown-item" src={require("../img/games-button.png")} onClick={() => navigateToLobby()} />
                <img className="dropdown-item" src={require("../img/monthly-scores.png")} onClick={() => navigateToMonthly()} />
                <img className="dropdown-item" src={require("../img/coins-earned-button.png")} onClick={() => navigateToCoinsEarned()} />
                <img className="dropdown-item" src={require("../img/ranking-button.png")} onClick={() => navigateToRanking()} />
              </div>
            </div>
          </div>
        }
      </div>
      <div className="nav-section diamond">
        <img id ="subDiamond" className="item diamond" src={require(`../img/diamond${isSubscriber ? '-gold' : ''}.gif`)} onClick={() => navigateToSubscribe()} />
      </div>
      <div className="nav-section logo-section">
        <img className="logo item" src={require("../img/logo-v3.png")} onClick={() => navigateToLobby()} />
      </div>
      <div className="nav-section coins">
        <img className="item coinHolderImage" src={require("../img/coin-holder.png")} />
        <div className="coinTextContainer" onClick={() => navigateToCoinsEarned()}>
          <span id="coin-count" className="coinCount">{coins}</span>
        </div>
      </div>
      <div className="nav-section team-rank">
        <img id="teamIconId" className="item team-rank-icon" src={require(`../img/${rankImage}`)} onClick={() => navigateToRanking()} />
      </div>
    </nav>
  );
}

export default NavBar;
