import React, { ref, forwardRef, useImperativeHandle, useState, useRef } from 'react';
import Plus10 from '../img/plus-10.png';
import Plus30 from '../img/plus-30.png';

import '../css/PlusImage.css';

const PlusImage = forwardRef(({}, ref) => {
  const [showPlusImage, setShowPlusImage] = useState(true);
  const plusImageRef = useRef(null);
  const [img, setImg] = useState(Plus10);

  const timeouts = useRef([]);

  const triggerPlusImage = (pulseIm) => {
    setImg(pulseIm);

    timeouts.current.forEach(clearTimeout); // Clear existing timeouts
    timeouts.current = []; // Reset the timeouts array

    const plusImage = plusImageRef.current;
    plusImage.className = 'pulse-image pop-up';

    timeouts.current.push(setTimeout(() => {
        plusImage.className = 'pulse-image pop-down';
    }, 1250));

    timeouts.current.push(setTimeout(() => {
        plusImage.className = 'pulse-image';
    }, 1750));
    };

    useImperativeHandle(ref, () => ({
        triggerPlusImage,
    }), []);

  return (
    <div ref={plusImageRef} className='pulse-image'>
            <img src={img} style={{width: "100%", marginLeft: "10vh"}}></img>
    </div>
  );
});

export default PlusImage;
