import React, { useState, useImperativeHandle, forwardRef } from 'react';
import PoopCountdownGif from '../img/gifs/poop-countdown-v2.gif';

const Timer = forwardRef((props, ref) => {
  const [imageSrc, setImageSrc] = useState(PoopCountdownGif);
  const [showAnim, setShowAnim] = useState(false);

  function triggerAnimation() {
    setShowAnim(true);
    setImageSrc(`${PoopCountdownGif}?${Date.now()}`)
  }

  function poopAlarmIsShowing() {
    return showAnim;
  }

  function hideAnimation() {
    setShowAnim(false);
    setImageSrc("");
  }

  useImperativeHandle(ref, () => ({
    triggerAnimation,
    hideAnimation,
    poopAlarmIsShowing,
  }));

  return (
    <>
      {showAnim && <div id='poopCentreTimer'>
        <img src={imageSrc} alt="Poop" className="poop-image" />
      </div>
      }
    </>
  );
});

export default Timer;
