import React, { forwardRef, useImperativeHandle, useState } from 'react';
import PowerModeText from '../img/power-prompt-v2.png';
import '../css/PowerMode.css';

const PowerMode = forwardRef((props, ref) => {
  const [isVisible, setIsVisible] = useState(false);

  useImperativeHandle(ref, () => ({
    setIsVisible,
  }));

  return (
    isVisible && (
      <div className="power-mode">
        <img src={PowerModeText} className='powerModeImage' alt="Power Mode" />
      </div>
    )
  );
});

export default PowerMode;
