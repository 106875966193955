const playAudio = (audio) => {
    let cleanupCalled = false;
  
    const play = () => {

      if (audio) {
        audio.play().catch((error) => {
          console.error('Playback error:', error);
        });
    
        audio.onended = () => {
          if (!cleanupCalled) {
            cleanupCalled = true;
            cleanupAudio();
          }
        };
      }
    };
  
    const cleanupAudio = () => {
      if (audio) {
        audio.src = '';
        audio.onended = null;
        audio = null;
      }
    };
  
    // Return an object with both the play and cleanup functions
    return {
      play,
      cleanup: cleanupAudio,
    };
  };

export default {
    playAudio
}