import React from 'react';
import '../css/Player.css';

function formatDate(timestamp, locale) {
  const date = new Date(timestamp);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const year = String(date.getFullYear()).slice(-2);

  if (locale === 'en-GB') {
    return `${day}/${month}/${year}`; // Non-American format
  } else {
    return `${month}/${day}/${year}`; // American format
  }
}

function mapNumToOrdinal(num) {
  if (num == 1) return "1st";
  if (num == 2) return "2nd";
  if (num == 3) return "3rd";
  if (num == 4) return "4th";
  if (num == 5) return "5th";

  // Fallback if we get something else
  return num;
}

const Player = ({ score, index, locale }) => {
  return (
    <div className="player">
      <div className='player-rank' >
        {mapNumToOrdinal(index + 1)}
      </div>
      <span className="player-name">{Math.round(score.score)}</span>
      <span className="player-date">{formatDate(score.time, locale)}</span>
    </div>
  );
};

export default Player;