



import React, { useImperativeHandle, forwardRef, useState, useRef, useEffect } from 'react';

import Energy50US from '../audio/50-energy-us.mp3';
import Energy75US from '../audio/75-energy-us.mp3';
import Energy100US from '../audio/100-energy-us.mp3';

import Energy50UK from '../audio/50-energy-uk.mp3';
import Energy75UK from '../audio/75-energy-uk.mp3';
import Energy100UK from '../audio/100-energy-uk.mp3';

import AudioUtil from '../utils/audioFunctions';

const EnergyTimer = forwardRef(({ doEnergyWarning, setRanOutOfEnergy, energyCountdown, energyCountdownSpeed, locale }, ref) => {
  const [timerIsPaused, setTimerIsPaused] = useState(false);
  const isLowEnergy = useRef(false);

  const [isLastSeconds, setIsLastSeconds] = useState(false);

  useImperativeHandle(ref, () => ({
    setTimerIsPaused,
    timerIsPaused,
    setIsLastSeconds,
    isLastSeconds,
  }));

  useEffect(() => {
    if (!timerIsPaused) {
        // const poopSpeed = poopTimer.current < 6 ? 500 : 500 * poopCountdownSpeed.current;
        const energySpeed = isLastSeconds ? 300 : energyCountdownSpeed.current * 500;

        let cleanupNotif;
        const energyCountdownInterval = setInterval(() => {
          energyCountdown.current -=1;
  
          let stop1 = document.querySelector('#stop1');
          // Adjust the offset and color of the stop elements based on the new energyCountdown value
          stop1.setAttribute('offset', `${energyCountdown.current}%`);
  
          if (energyCountdown.current >= 70 && stop1.style.stopColor != 'yellow') {
              stop1.style.stopColor = 'yellow';
          } else if (energyCountdown.current < 70 && energyCountdown.current >= 35 && stop1.style.stopColor != 'orange') {
              stop1.style.stopColor = 'orange';
          } else if (energyCountdown.current < 25 && stop1.style.stopColor != 'red') {
              stop1.style.stopColor = 'red';
          }
  
          // Play notification sound at certain points
            const EnergyLevels = {
              100: locale == "en-GB" ? Energy100UK : Energy100US,
              75: locale == "en-GB" ? Energy75UK : Energy75US,
              50: locale == "en-GB" ? Energy50UK : Energy50US,
            };
            
            if (EnergyLevels.hasOwnProperty(energyCountdown.current)) {
              const countdownAudio = new Audio(EnergyLevels[energyCountdown.current]);
              const {play: playNotification, cleanup: cleanupNotification} = AudioUtil.playAudio(countdownAudio);
              
              playNotification();
  
              cleanupNotif = cleanupNotification;
              // cleanupFuncsRef.current.push(() => cleanupNotification());
            }
  
          // Quicker countdown sound
          if (energyCountdown.current === 25) {
            // We start playing the global one
            const finalCountDown = document.getElementById("final-energy-countdown");
            finalCountDown.play().catch((err) => console.log(JSON.stringify(err)));
          } 
          
          if (energyCountdown.current <= 0) {
            // Use Energy Ref.
            setRanOutOfEnergy(true)
          } else if (energyCountdown.current <= 25 && !isLowEnergy.current) {
            isLowEnergy.current = true;
            doEnergyWarning(true);
          } else if (energyCountdown.current > 25 && isLowEnergy.current) {
            isLowEnergy.current = false;
            doEnergyWarning(false);
          } else if (energyCountdown.current <= 4) {
            console.log(energySpeed)
            // We do a slow count down for the last bit of it, we make it 200ms speed, so they have 1 second
            // if (!isLastSeconds) {
            //   setIsLastSeconds(true);
            // }
          }
          // Just set these values
        }, energySpeed);
  
        // cleanupFuncsRef.current.push(
        //   // () => clearInterval(poopTimerInterval),
        //   () => clearInterval(energyCountdownInterval),
        // );
    
        return () => {
          clearInterval(energyCountdownInterval);
          if (cleanupNotif) {
            cleanupNotif();
          }
        };
      }
  }, [timerIsPaused, isLastSeconds]);

  // You can expose setTimerIsPaused through useImperativeHandle if needed

  return null; // Render nothing, since this component only handles logic
});

export default EnergyTimer;

