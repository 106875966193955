import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import '../css/FoodAnimation.css';

const FoodAnimation = forwardRef(({ foodImage, id, style }, ref) => {
  const [showAnimation, setShowAnimation] = useState(false);
  const timerRef = useRef(null);

  useImperativeHandle(ref, () => ({
    showFoodAnimation: () => {
      const timeToStart = Math.round(Math.random() * 2000);

      // We start it after a random time between 0 and 2 secs
      timerRef.current = setTimeout(() => {
        setShowAnimation(true);
      }, timeToStart);
    },
  }));

  useEffect(() => {
    // Clear the timeout when the component is unmounted
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  return (
    showAnimation && 
    <div className={`food-animation-container ${id}`} style={{...style}}>
        <div
          className="food-animation-image"
          style={{ animationPlayState: 'running' }}
          onAnimationEnd={() => setShowAnimation(false)}
        >
          <img src={foodImage} alt="Food Animation" />
        </div>
    </div>
  );
});



export default FoodAnimation;
