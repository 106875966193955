
import FoodAnimation from "./FoodAnimation";
import PropTypes from 'prop-types';

import FoodMap from "../utils/FX";
import React, { useEffect, useState, useImperativeHandle, forwardRef, useRef } from "react";

const MultipleFoodAnimation = forwardRef((props, ref) => {
    const [foodImage, setFoodImage] = useState(null);
    const foodRefs = useRef(Array(4).fill(null));

    const showAllFoodAnimations = () => {
      foodRefs.current.forEach((ref) => ref && ref.showFoodAnimation(true));
    };

    useImperativeHandle(ref, () => ({
      doAllFoodAnimations: (foodImage) => {
        setFoodImage(foodImage);
        showAllFoodAnimations();
        return foodImage;
      },
  }));
    
    return ( 
        <div className="pizza-container">
              <FoodAnimation ref={ref => foodRefs.current[0] = ref} foodImage={foodImage} id="food-1" style={{left: "15vh"}} />
              <FoodAnimation ref={ref => foodRefs.current[1] = ref} foodImage={foodImage} id="food-2" style={{left: "0vh"}} />
              <FoodAnimation ref={ref => foodRefs.current[2] = ref} foodImage={foodImage} id="food-3" style={{left: "-15vh"}} />
              <FoodAnimation ref={ref => foodRefs.current[3] = ref} foodImage={foodImage} id="food-4" style={{left: "-30vh"}} />
        </div>
    );
  });
  
  
  export default MultipleFoodAnimation;
  