import Player from './Player';
import '../css/Leaderboard.css';

import LeaderboardTitle from "../img/top-scores-text.png"
import { AlexaContext } from '../utils/AlexaService';
import { useNavigate, Link } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import playAgainButton from "../img/play-button.png"
import switchGameButton from "../img/switch-game.png";
import Loading from './Loading';

const Leaderboard = () => {
  let { message, seagullData, sendMessage, topScores } = useContext(AlexaContext);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const returnToSkill = () => {
    setIsLoading(true);
    sendMessage.current(
      {
        switchGame: true
      } 
    );
  };


  const replayGame = () => {
    if (seagullData.playsLeft <= 0) {
      sendMessage.current(
        {
          upsell: true
        }
      );
    } else {

      if (seagullData.isHighPerformance) {
        sendMessage.current(
          {
            switchState: "GAME_PLAY",
          }
        );
        navigate("/play")
      } else {

        sendMessage.current(
          {
            "restartWebsite": true
          }
        )


      }
    }
  }

  useEffect(() => {
    if (message && message.navigateTo === 'game') {
      replayGame();
    }
  }, [message]);


  if (isLoading) {
    return (
      <Loading isLoading={isLoading} />
    )
  } else {
    return (
      <div className="layout-container">

    <div className="button-wrapper" onClick={replayGame}>
              <img className="leaderboardButton" src={playAgainButton} alt="Play Again Button" />
            </div>

        <div className="leaderboard-container">
          <div className="leaderboard">
            <div className="leaderboard-backer">
              <img className="leaderboard-title" src={LeaderboardTitle} alt="Leaderboard Title"/>
              {
                topScores.current.length ? <div className="players-container">
                {topScores.current.map((score, i) => (
                  <Player score={score} locale={seagullData.locale} index={i}/>
                ))}
              </div> : <div className='no-highscores'> 
              <div> Squarkkkkk</div> 
                <div> No Scores Set</div> 
                </div>
              }
  
            </div>
          </div>
        </div>
  
        

  
        <div className="button-wrapper" onClick={returnToSkill}>
          <img className="leaderboardButton" src={switchGameButton} alt="Switch Game Button"  />
        </div>
        </div>

    );
  }


  
    
    
};

export default Leaderboard;
