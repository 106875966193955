import React, { useState, useImperativeHandle, forwardRef } from 'react';

const HighScore = forwardRef((props, ref) => {
    const [score, setScore] = useState(false);
    const [newHighScore, setNewHighScore] = useState(false);

    useImperativeHandle(ref, () => ({
        setShowHighScore: (points, isHighScore = false) => {
            setScore(points);
            setNewHighScore(isHighScore)
        }
    }));

    return (
        score && <div className="score-container">
            <div className="score-wrapper">
                <div className="score-frame">
                    <div className={newHighScore ? 'new-high-score' : "high-score"}>{newHighScore ? 'NEW HIGH SCORE: ' : 'SCORE: '} {score}</div>
                </div>
            </div>
        </div>
    );
});

export default HighScore;
