import Pizza from '../audio/food-comments/pizza.mp3';
import BakedPotato from '../audio/food-comments/baked-potato.mp3';
import BeefWellington from '../audio/food-comments/beef-wellington.mp3';
import CheeseBurger from '../audio/food-comments/cheese-burger.mp3';
import CheeseCake from '../audio/food-comments/cheese-cake.mp3';
import CheeseSandwich from '../audio/food-comments/cheese-sandwich.mp3';
import ChickenNuggets from '../audio/food-comments/chicken-nuggets.mp3';
import ChocolateIceCream from '../audio/food-comments/chocolate-icecream.mp3';
import Donut from '../audio/food-comments/donut-2.mp3';
import OtherDonut from '../audio/food-comments/donut.mp3';
import FrenchFries from '../audio/food-comments/french-fries.mp3';
import HotDog from '../audio/food-comments/hotdog.mp3';
import Milkshake from '../audio/food-comments/milkshake.mp3';
import Nachos from '../audio/food-comments/nachos.mp3';
import OnionRings from '../audio/food-comments/onion-rings.mp3';
import Popcorn from '../audio/food-comments/popcorn.mp3';
import Ravioli from '../audio/food-comments/ravioli.mp3';
import Soda from '../audio/food-comments/soda.mp3';
import Tacos from '../audio/food-comments/tacos.mp3';
import Watermelon from '../audio/food-comments/watermelon.mp3';
import BakedPotatoImg from '../img/food-images/baked-potato.png';
import BeefWellingtonImg from '../img/food-images/beef-wellington.png';
import CheeseBurgerImg from '../img/food-images/cheeseburger.png';
import CheeseCakeImg from '../img/food-images/cheese-cake.png';
import CheeseSandwichImg from '../img/food-images/cheese-sandwich.png';
import ChickenNuggetsImg from '../img/food-images/chicken-nugget.png';
import ChocolateIceCreamImg from '../img/food-images/chocolate-ice-cream.png';
import DonutImg from '../img/food-images/donut-1.png';
import OtherDonutImg from '../img/food-images/donut-1.png';
import FrenchFriesImg from '../img/food-images/french-fries.png';
import HotDogImg from '../img/food-images/hotdog.png';
import MilkshakeImg from '../img/food-images/milkshake.png?t=1';
import NachosImg from '../img/food-images/nacho.png';
import OnionRingsImg from '../img/food-images/onion-ring.png';
import PopcornImg from '../img/food-images/popcorn.png';
import RavioliImg from '../img/food-images/ravioli.png';
import SodaImg from '../img/food-images/soda.png';
import TacosImg from '../img/food-images/taco.png';
import WatermelonImg from '../img/food-images/watermelon.png';
import PizzaImg from '../img/food-images/pizza-slice.png';


import Cookies from '../audio/food-comments/cookies.mp3';
import Cheese from '../audio/food-comments/cheese.mp3';
import Noodles from '../audio/food-comments/noodles.mp3';
import Spaghetti from '../audio/food-comments/spaghetti.mp3';
import Sushi from '../audio/food-comments/sushi.mp3';
import Victoria from '../audio/food-comments/victoria.mp3';

import CookiesImg from '../img/food-images/cookie.png';
import CheeseImg from '../img/food-images/cheese.png';
import NoodleImg from '../img/food-images/noodles.png';
import SpaghettiImg from '../img/food-images/spaghetti.png';
import SushiImg from '../img/food-images/sushi.png';
import CakeImg from '../img/food-images/cake-slice.png';


const FoodMap = [
    {
      img: BakedPotatoImg,
      audio: BakedPotato
    },
    {
      img: BeefWellingtonImg,
      audio:  BeefWellington
    },
    {
      img: CheeseBurgerImg,
      audio:  CheeseBurger
    },
    {
      img: CheeseCakeImg,
      audio: CheeseCake
    },
    {
      img: CheeseSandwichImg,
      audio: CheeseSandwich
    },
    {
      img: ChickenNuggetsImg,
      audio: ChickenNuggets
    },
    {
      img: ChocolateIceCreamImg,
      audio: ChocolateIceCream
    },
    {
      img: DonutImg,
      audio: Donut
    },
    {
      img: OtherDonutImg,
      audio: OtherDonut
    },
    {
      img: FrenchFriesImg,
      audio: FrenchFries
    },
    {
      img: HotDogImg,
      audio: HotDog
    },
    {
      img: MilkshakeImg,
      audio: Milkshake
    },
    {
      img: NachosImg,
      audio: Nachos
    },
    {
      img: OnionRingsImg,
      audio: OnionRings
    },
    {
      img: PopcornImg,
      audio: Popcorn
    },
    {
      img: RavioliImg,
      audio: Ravioli
    },
    {
      img: SodaImg,
      audio: Soda
    },
    {
      img: TacosImg,
      audio: Tacos
    },
    {
      img: WatermelonImg,
      audio: Watermelon
    },
    {
      img: PizzaImg,
      audio: Pizza
    },
    {
      audio: Cookies,
      img: CookiesImg,
    },
    {
      audio: Cheese,
      img: CheeseImg,
    },
    {
      audio: Noodles,
      img: NoodleImg,
    },
    {
      audio: Cookies,
      img: CookiesImg,
    },
    {
      audio: Spaghetti,
      img: SpaghettiImg,
    },
    {
      audio: Sushi,
      img: SushiImg,
    },
    {
      audio: Victoria,
      img: CakeImg,
    }
  ]




  export default FoodMap