import React from 'react';

const PoopCountdown = ({poopCountdown}) => {

  poopCountdown = poopCountdown ?? 0;

  return (
    <div style={{width:"20vh"}} >
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1976.000000 1927.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <defs>
          <linearGradient id="poopGradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop id="poopStop1" offset={`${poopCountdown}%`} style={{ stopColor: '#a05a2c', stopOpacity: 1 }} />
            <stop id="poopStop3" offset={`${poopCountdown}%`} style={{ stopColor: 'transparent', stopOpacity: 1 }} />          
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,1927.000000) scale(0.100000,-0.100000)"
           stroke="black" strokeWidth="700">
          <path style={{fill:'url(#poopGradient)'}} d="M8441 17678 c-4 -13 -11 -115 -15 -228 -15 -481 -72 -717 -269 -1115
          -278 -560 -331 -749 -402 -1420 -40 -378 -72 -541 -126 -647 -32 -62 -107
          -137 -171 -169 -105 -53 -191 -71 -583 -124 -318 -42 -525 -80 -583 -105 -203
          -90 -704 -692 -898 -1080 -57 -114 -74 -168 -86 -275 -32 -288 115 -1395 248
          -1870 20 -71 49 -177 65 -235 38 -134 69 -314 69 -395 0 -151 -53 -279 -151
          -368 -80 -73 -160 -110 -304 -142 -294 -65 -442 -138 -620 -306 -287 -273
          -462 -580 -649 -1141 -112 -334 -156 -558 -163 -828 -6 -219 9 -348 62 -507
          58 -175 126 -292 266 -458 136 -161 190 -264 193 -365 2 -80 -26 -137 -73
          -149 -94 -25 -343 -45 -776 -61 -560 -22 -956 -54 -1051 -86 -158 -52 -384
          -238 -503 -413 -99 -146 -204 -414 -240 -616 -39 -217 -46 -343 -46 -800 1
          -560 23 -823 90 -1070 51 -185 134 -355 254 -517 66 -88 96 -118 186 -186 268
          -201 831 -487 1028 -522 177 -32 1466 -49 3652 -50 4424 0 7448 76 8565 216
          788 99 1438 376 1862 796 122 120 181 195 319 403 l113 170 -18 235 c-68 924
          -137 1248 -343 1600 -83 141 -220 341 -320 465 -121 152 -368 395 -623 615
          -397 343 -467 414 -537 545 -37 68 -38 73 -38 175 0 95 4 119 42 245 94 309
          143 719 143 1196 0 346 -27 576 -79 676 -52 100 -187 228 -371 352 -298 200
          -589 328 -869 381 -236 44 -337 106 -392 240 -21 51 -18 264 5 360 31 132 54
          281 66 426 17 190 8 697 -14 863 -58 438 -181 710 -392 870 -91 68 -269 158
          -424 214 -69 25 -249 81 -400 126 -493 144 -646 208 -754 315 -88 89 -103 171
          -116 631 -14 475 -37 701 -105 1041 -65 320 -155 581 -267 774 -437 753 -1169
          1410 -2079 1866 -377 188 -756 330 -1167 435 -179 46 -199 47 -211 17z"/>
        </g>
      </svg>
    </div>
  );
};

export default PoopCountdown;
