import React, { useImperativeHandle, forwardRef, useRef } from 'react';
import ssBubble from '../img/ss-bubble.png';

import { useEffect } from 'react';

const SpeechBubble = forwardRef((props, ref) => {
    const speechBubbleRef = useRef(null);
    const timeouts = useRef([]);

    const triggerSpeechBubble = () => {
        timeouts.current.forEach(clearTimeout); // Clear existing timeouts
        timeouts.current = []; // Reset the timeouts array

        const bubble = speechBubbleRef.current;
        bubble.className = 'speech-bubble pop-up';

        timeouts.current.push(setTimeout(() => {
            bubble.className = 'speech-bubble pop-down';
        }, 2000));

        timeouts.current.push(setTimeout(() => {
            bubble.className = 'speech-bubble';
        }, 3000));
    };

    useImperativeHandle(ref, () => ({
        triggerSpeechBubble,
    }), []);

    useEffect(() => {
        return () => {
            timeouts.current.forEach(clearTimeout); // Clear timeouts on unmount
        };
    }, []);

    return (
        <div className="speech-bubble-container">
            <div className="speech-bubble" ref={speechBubbleRef}>
                <img src={ssBubble} alt="Speech Bubble" />
            </div>
        </div>
    );
});

export default SpeechBubble;

