import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import HighScoreFx from '../audio/highscore-v2.mp3';
import AudioUtil from '../utils/audioFunctions';
import RiskyDing from '../audio/risky-ding.mp3';

const DistanceCounter = forwardRef(({ distance, topScores, doHighScorePlane, planeIsFlying }, ref) => {
  const [riskyEater, setRiskyEater] = useState(false);

  useImperativeHandle(ref, () => ({
    setRiskyEater,
  }), [riskyEater]);

  useEffect(() => {
    let riskyDing;

    if (riskyEater) {
      riskyDing = new Audio(RiskyDing);

      const handleAudioEnd = () => {
        setRiskyEater(false);
      };

      riskyDing.addEventListener('ended', handleAudioEnd);

      riskyDing.play().catch(error => {
        console.error("Failed to play the sound:", error);
        setRiskyEater(false);
      });

      return () => {
        if (riskyDing) {
          riskyDing.removeEventListener('ended', handleAudioEnd);
          riskyDing.pause(); // Pause the audio
          riskyDing.currentTime = 0; // Optionally, reset the time
        }
      };
    }
  }, [riskyEater]);

  useEffect(() => {
    let highScoreClean;
    const distanceIncrementInterval = setInterval(() => {
      distance.current = distance.current + 1;

      // The top score is the 0th value
      if (topScores?.current[0]) {
        const leaderboardScore = topScores.current[0].score;
        const doFlyPlane = planeIsFlying();

        if ((distance.current > leaderboardScore && leaderboardScore > 0) && !doFlyPlane) {
          const highScoreAudio = new Audio(HighScoreFx);
          const { play: playHighScore, cleanup: cleanupHighScore } = AudioUtil.playAudio(highScoreAudio);

          playHighScore();
          highScoreClean = cleanupHighScore;

          doHighScorePlane(true); // Call the parent's method to trigger animation
        }
      }

      document.querySelector('.distance-number').innerText = distance.current;
    }, riskyEater ? 100 : 500);

    return () => {
      clearInterval(distanceIncrementInterval);

      if (highScoreClean) {
        highScoreClean();
      }
    };
  }, [riskyEater]); // Dependencies are intentionally left empty here

  return (
    <div className="distance-container">
      <p className="distance-label">DISTANCE</p>
      <p className={riskyEater ? "distance-value-highscore" : "distance-value"}>
        <span className="distance-number">{distance.current}</span>M
      </p>
    </div>
  );
});

export default DistanceCounter;
