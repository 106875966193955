import { useEffect, useContext, useState, useRef } from 'react';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import '../css/SplashScreen.css';
import '../css/GameOver.css';
import { AlexaContext } from '../utils/AlexaService';

import sillySeagullTitle from '../img/seagull-text-v4.png';
import playButton from '../img/play-button.png';
import leaderboardButton from '../img/top-scores-button.png';
import GameSpriteAnimation from './GameSpriteAnimation';
import switchGameButton from "../img/switch-game.png";
import AudioUtil from '../utils/audioFunctions'; // Replace with the correct import path
import CoinFx from '../utils/CoinFx'; // Replace with the correct import path
// Regular Score
import RegularGameOver from '../audio/game-complete-v2.mp3';

import TutorialModal from './TutorialModal';

// OK SCORE
import OkScore from '../audio/ok-score.mp3';
// Bad Score
import GameOverFx1 from '../audio/game-over-fx-1.mp3';
import GameOverFx2 from '../audio/game-over-fx-2.mp3';
import GameOverFx3 from '../audio/game-over-fx-3.mp3';


// Good Score
import GoodWin from '../audio/good-win.mp3';

// Great Score
import BigWin from '../audio/big-win.mp3';


// HighScore
import GameOverHighScore from '../audio/new-highscore.mp3';


import CoinAnimation from './CoinAnimation';
import BackingTrackAudio from '../audio/backing-track-quiet-v2.mp3';

import Loading from './Loading';
import HighScore from './HighScore';

function getRandomElement(arr) {
  // Generate a random index based on the array length
  const randomIndex = Math.floor(Math.random() * arr.length);
  return arr[randomIndex];
}

function calculateCoinsEarned(score) {
  return Math.floor(score / 50);
}

function isNewScore(score, topScores) {
  let isNew;

  if (topScores.length < 5) isNew = true;

  for (let x = 0; x < topScores.length; x ++) {
    if (topScores[x].score < score) isNew = true;
  }
  return isNew;
}

// It is the first ranked score
function isHighestScore(score, topScores) {
  if (topScores && topScores[0]) {
    return score > topScores[0].score;
  }
}

const SplashScreen = () => {
  const navigate = useNavigate();
  const { message, sendMessage, seagullData, topScores } = useContext(AlexaContext);
  const location = useLocation();
  const [isGameOver, setIsGameOver] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const coinRef = useRef(null);
  const highScoreRef = useRef(null);
  const tutorialRef = useRef(null);

  const setCoinAnim = (score) => {
    coinRef.current.playAnimation(score)
  }

  const setShowHighScore = (score, isNewHighScore) => {
    highScoreRef.current.setShowHighScore(score, isNewHighScore);
  }

  const setShowTutorial = (setTut) => {
    tutorialRef.current.setShowTutorial(setTut)
  }


  const returnToSkill = () => {
    setIsLoading(true);
    sendMessage.current(
      {
        switchGame: true
      }
    );
  };

  // useEffect(() => {
  //   setCoinAnim(100);
  // }, [])


  useEffect(() => {
    if (seagullData.skipIntro) {
      seagullData.skipIntro = false;
      navigate("/play");
    } else if (seagullData.showTutorial) {
      seagullData.showTutorial = false;
      // We want to show the view for the duration of the speech, then hide it.
      const introTimeOut = setTimeout(() => {
        setShowTutorial(true)
      }, 10000)

     const tutTimeOut = setTimeout(() => {
        setShowTutorial(false);
      }, 31000)

      return () => {
        clearTimeout(introTimeOut);
        clearTimeout(tutTimeOut);
      }
    }

  }, [seagullData]);

  useEffect(() => {
    if (message && message.isGameOver) {
      console.log("GAME OVER...")

      setIsGameOver(true);
   
      const score = location?.state?.score;
      const reason = location?.state?.reason;
      const coinsEarned = calculateCoinsEarned(score);
      const isNew = isNewScore(score, topScores.current);
      const isNewHighScore = isHighestScore(score, topScores.current);

      if (isNew) {
        // Then we update top scores.
        topScores.current.push({
            score,
            time: new Date().getTime(),
          }
        );
        topScores.current = topScores.current.sort((a, b) => b.score - a.score).slice(0, 5);
      }

      setShowHighScore(score, isNewHighScore);

      sendMessage.current(
        {
          reason: reason,
          setGameOver: true,
          coinsEarned,
          gameOnScore: score, // Dont bother sending if its not in the top 5.
          isNewHighScore,
        }
      );

      let coinCleanup;

      if (coinsEarned > 0) {
        let coinSound = CoinFx[Math.min(19, coinsEarned - 1)];
        // Now generate the SFX
        const coinSoundFx = new Audio(coinSound);
  
        const { play: playCoinSound, cleanup: cleanUpCoinSound } = AudioUtil.playAudio(coinSoundFx);
  
        playCoinSound();

        coinCleanup = cleanUpCoinSound;


        setCoinAnim(coinsEarned)
      }

      const audioEl = document.getElementById("audio-element");
      audioEl.play().catch((err) => console.log(err));

      let randomGameOver = [];

      // Do HighScore FX in the skill
     if (score < 50) {
        randomGameOver = [
          GameOverFx1,
          GameOverFx2,
          GameOverFx3
        ];
      } else {
        randomGameOver = [RegularGameOver];
      } 

      let gameOverAudio = new Audio(getRandomElement(randomGameOver));

      gameOverAudio.play().catch((err) => console.log(JSON.stringify(err)));
      gameOverAudio.onended = () => {
        gameOverAudio = null;
      }

      return () => {
        audioEl.pause(); // Pause the audio
        audioEl.currentTime = 0; // Reset the audio to the beginning

        if (coinCleanup) {
          coinCleanup();
        }
      };
    }

    if (message && message.navigateTo === "game") {
      changeToGameState();
    }

    if (message && message.navigateTo === "ranking") {
      navigate('/leaderboard');
    }
  }, [message]);


  const changeToGameState = () => {
    if (isGameOver) {
      // setSeagullData(
      //   {
      //       playsLeft: seagullData.playsLeft - 1,
      //       ...seagullData, 
      //   }
      // )
      seagullData.playsLeft --;
      if (seagullData.playsLeft <= 0) {
        sendMessage.current(
          {
            upsell: true
          }
        );

        setIsLoading(true);
      } else {
        if (true) {
          sendMessage.current(
            {
              switchState: "GAME_PLAY",
            }
          );

          navigate("/play")
        } else {
          sendMessage.current(
            {
              "restartWebsite": true
            }
          )
        }
      }
    } else {
      sendMessage.current({ switchState: "GAME_PLAY" });
      navigate('/play'); // Manually navigate to the '/play' route
    }
  }

  if (isLoading) {
    return (
      <Loading isLoading={isLoading} />
    )
  } else {
    return (
      <div style={{overflowY: "10vh"}}>
        <CoinAnimation ref={coinRef} />

        <div className="splash-screen">
        <audio id="audio-element" src={BackingTrackAudio} preload="auto" loop></audio>
        <div className='tutorialBird'>
          <GameSpriteAnimation />
        </div>
        <HighScore ref={highScoreRef} />

        <TutorialModal ref={tutorialRef}/>

        <div className="splashContent">
          <img className="tutorial-splashLogo" src={sillySeagullTitle} alt="Silly Seagull" />
        </div>
        <div className="splashButtons">
          <div>
            <img
              className="splashButton"
              src={playButton}
              alt="Play Button"
              onClick={changeToGameState}
            />
          </div>

          <Link to="/leaderboard">
            <img className="splashButton" src={leaderboardButton} alt="Leaderboard Button" />
          </Link>
          <div onClick={returnToSkill}>
            <img className="splashButton" src={switchGameButton} alt="Switch Game Button" />
          </div>
        </div>
      </div>
      </div>

    )
  }
};

export default SplashScreen;
