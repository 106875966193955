import React, { useState, useEffect, useRef } from 'react';

const EnergyCountdown = ({energyCountdown}) => {

  energyCountdown = energyCountdown ?? 0;
  return (
    <div style={{width:"15vh"}} >
      <svg
        version="1.0"
        viewBox="0 0 34.000000 52.000000"
        preserveAspectRatio="xMidYMid meet"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <linearGradient id="gradient" x1="0%" y1="100%" x2="0%" y2="0%">
            <stop id="stop1" offset="45%" style={{ stopColor: 'yellow', stopOpacity: 1 }} />
            <stop id="stop3" offset={`${energyCountdown}%`} style={{ stopColor: 'transparent', stopOpacity: 1 }} />
          </linearGradient>
        </defs>
        <svg
        version="1.0"
        viewBox="0 0 34.000000 52.000000"
        preserveAspectRatio="xMidYMid meet"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g transform="matrix(0.1,0,0,-0.1,0.12776413,52.638821)" fill="black" stroke="black" id="g247">
          <path d="M 91,389 C 40,317 0,250 2,241 4,229 19,224 64,222 l 58,-3 -4,-87 c -3,-83 -2,-87 19,-90 17,-3 37,19 106,120 46,68 87,128 90,135 13,22 -23,43 -71,43 h -48 l 1,87 c 0,128 -12,124 -124,-38 z m 104,16 -7,-95 h 62 c 34,0 60,-4 58,-9 C 301,281 144,61 138,60 c -4,0 -5,21 -2,48 2,26 7,66 9,90 l 5,42 H 84 c -36,0 -64,3 -62,8 6,15 172,252 176,252 3,0 1,-43 -3,-95 z" id="path245" />
        </g>
        <path
          style={{ fill: 'url(#gradient)', strokeWidth: '0.0851761' }}
          d="m 18.408942,61.985984 c -0.213755,-0.399406 -0.28724,-1.359619 -0.242049,-3.162803 0.04716,-1.881575 0.22239,-3.916178 0.822407,-9.548816 0.187139,-1.756756 0.400327,-3.807371 0.473751,-4.55692 0.07342,-0.74955 0.266289,-2.464604 0.428589,-3.811233 0.162301,-1.346628 0.295092,-2.522199 0.295092,-2.61238 0,-0.160096 -0.156211,-0.164878 -6.62244,-0.20273 C 6.6900819,38.050862 5.6439728,38.001788 4.1183878,37.647981 3.3698534,37.474385 3.1231474,37.318314 3.2018339,37.068152 3.4398466,36.311457 10.008754,26.647282 17.824253,15.555652 23.269066,7.8284615 26.409525,3.5603404 26.575058,3.6626452 c 0.145621,0.089999 0.180565,1.5276704 0.09005,3.7050862 -0.123816,2.9786486 -0.24607,4.8415466 -0.901801,13.7415986 -0.303154,4.114637 -0.55119,7.554686 -0.55119,7.644553 0,0.159298 0.130926,0.163406 5.217035,0.163745 5.218613,3.4e-4 6.786445,0.05675 8.534097,0.307031 1.628957,0.233281 2.327739,0.530664 2.177218,0.926566 -0.265021,0.697057 -3.034227,4.96393 -6.219652,9.583419 -6.736374,9.769061 -15.82113,22.210013 -16.34623,22.385047 -0.04391,0.01464 -0.118456,-0.04553 -0.165647,-0.133707 z"
          id="path324"
          transform="scale(0.75)"
        >
        </path>
      </svg>
      </svg>
    </div>
  );
};

export default EnergyCountdown;
