import React, { useState, useImperativeHandle, forwardRef } from 'react';
import '../css/GameView.css';
import PoopCountdown from './PoopCountdown';

const PoopContainer = forwardRef((props, ref) => {
  const [isPoopWarning, setPoopWarning] = useState(false);

  useImperativeHandle(ref, () => ({
    setPoopWarning,
    isPoopWarning,
  }));

  return (
        <div id="poop-bar-container">
        <div className="label-container">
        <p id="alexa-text">Alexa</p>
            <p id="eat-food-text">Release!</p>
        </div>
        <div className={`poop-bar ${isPoopWarning ? 'pulse' : ''}`}>
        <PoopCountdown/>
        </div>
    </div>
  );
});

export default PoopContainer;
