import '../css/GameSpriteAnimation.css';
import poopFullScreenLowQuality from '../img/gifs/poop-full-screen-v5.gif';
import poopFullScreenHighQuality from '../img/gifs/poop-full-screen-v3.gif';
import React, { useState, useImperativeHandle, forwardRef } from 'react';

const FullScreenSprite = forwardRef(({isHighPerformance}, ref) => {

  let imgToUse = isHighPerformance ? poopFullScreenHighQuality : poopFullScreenLowQuality;
  const [showAnim, setShowAnim] = useState(false);
  const [imgSrc, setImageSrc] = useState(imgToUse);


    function triggerAnimation() {
        setShowAnim(true);
        setImageSrc(`${imgToUse}?${Date.now()}`)
    }

    function hideAnimation() {
      setShowAnim(false);
    }

    useImperativeHandle(ref, () => ({
      triggerAnimation,
      hideAnimation,
     }), []);

  return (
    <div>
      {showAnim && <img
              src={imgSrc}
              alt="full screen sprite"
              style={{
                position: 'fixed',
                top: "20vh",
                left: 0,
                height: '100vh',
                width: '100vw',
                objectFit: 'cover',
                zIndex: 9999,
              }}
            />
        }
    </div>

  );
});

export default FullScreenSprite;