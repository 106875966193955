import { AlexaProvider } from './utils/AlexaService';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SplashTutorial from './views/SplashTutorial';

import GameView from './views/GameView';
import Leaderboard from './views/Leaderboard';
import Parallax from './views/Parallax'
import LogView from './views/LogView';
import NavBar from './views/NavBar';

function App() {
  return (
    <>
      <NavBar />
      <Parallax />
      <AlexaProvider>
        {/* <LogView/> */}
        <Router>
          <Routes>
            <Route path="/play" element={<GameView />} />
            <Route path="/leaderboard" element={<Leaderboard />} />
            <Route path="/" element={<SplashTutorial />} />
          </Routes>
        </Router>
      </AlexaProvider>
    </>
  );
}

export default App;
