import React, { createContext, useEffect, useState, useRef } from 'react';
export const AlexaContext = createContext(null);

export const AlexaProvider = ({ children }) => {
  // const [log, setLog] = useState('Initializing...'); // set initial log message
  const [message, setMessage] = useState({});
  const [seagullData, setSeagullData] = useState({});
  const sendMessage  = useRef(() => {});
  const topScores = useRef([]);
  const requestMicrophoneOpen = useRef(null);

  useEffect(() => {
    window.Alexa.create({ version: '1.1' })
      .then((args) => {
        const {
          alexa,
          message
        } = args;

        setSeagullData(
          {
            isHighPerformance: message.isHighPerformance,
            skipIntro: message.skipIntro,
            playsLeft: message.playsLeft,
            isSubscriber: message.isSubscriber,
            topScores: message.topScores,
            locale: message.locale,
            showTutorial: message.showTutorial,
          }
        );

        if (message.deviceType === "FIRE_TV") {
          const logoElement = document.querySelector('.nav-section.logo-section img.logo');

          if (logoElement) {
            logoElement.style.marginTop = "2vh";
            logoElement.style.width = "55%";
          }




          const elements = document.querySelectorAll(
            '.nav-section.diamond .item.diamond, ' + 
            '.nav-section.burger .item.burger, ' + 
            '.nav-section.coins .team-rank-icon, ' + 
            '.nav-section.team-rank .team-rank-icon'
        );
        
        if (elements.length > 0) {
            elements.forEach(element => {
                element.style.marginTop = '1.5vh';
            });
        }
        }


        // We do this so we dont have to refresh the state when a top score is set.
        if (message.topScores) {
          topScores.current = message.topScores;
        }

        if (message.coins) {
          const coinCountElement = document.getElementById('coin-count');
          if (coinCountElement) {
            coinCountElement.textContent = message.coins;
          }
        }

        if (message.isSubscriber) {
          // Set the subscriber value
          const subDiamond = document.getElementById("subDiamond"); // Get the subDiamond
          if (subDiamond) {
            subDiamond.src = require("../img/diamond-gold.gif")
          }
        }

        if (message.rankImagePath) {
          const rankImage = document.getElementById('teamIconId');
          if (rankImage) {
            rankImage.src = require(`../img/team-icons/${message.rankImagePath}`)
          }
        }

        alexa.skill.onMessage((message) => {
          // console.log('Received message: ', message);
          setMessage(message);

        });

        sendMessage.current = (message, callback) => {
          alexa.skill.sendMessage(message, callback);
        }

        requestMicrophoneOpen.current = (config) => {
          alexa.voice.requestMicrophoneOpen(config);
        };

        window.sendMessage = (message, callback) => {
          alexa.skill.sendMessage(message, callback);
        }
      })
      .catch(error => {
        window.location.href = "https://hugo.fm";
        console.log(`Logging Error: ${JSON.stringify(error)}`);
      });
  }, []);

  return (
    <AlexaContext.Provider value={
      { 
        message, 
        seagullData, 
        setSeagullData, 
        sendMessage, 
        // log,
        topScores,
        // setLog,
        setMessage, 
        requestMicrophoneOpen: requestMicrophoneOpen.current, // Add this line
      }}>
      {children}
    </AlexaContext.Provider>
  );
};
