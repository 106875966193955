import React, { useContext, forwardRef, useImperativeHandle, useState, useRef } from 'react';
import '../css/PressableButton.css';
import { AlexaContext } from '../utils/AlexaService';
import EatFoodButton from '../img/eat-button-v2.png';
import ReleaseButton from '../img/release-button.png';

import EatFoodInactive from '../img/eat-button-inactive.png';

import EatFoodRefillGif from '../img/gifs/eat-refill-v3.gif';
import ReleaseIncative from '../img/release-button-inactive.png';

const PressableButton = forwardRef((props, ref) => {
    const {setMessage} = useContext(AlexaContext);

    const [toggleEat, setToggleEat] = useState(true);
    const [toggleRelease, setToggleRelease] = useState(true);
    const timeouts = useRef([]); // Store all timeouts in an array
    // We need one with a timeout that just disabled the relevant button for 5 seconds.

    useImperativeHandle(ref, () => ({
        disableRelease,
        disableEat,
      }));

    function disableEat() {
        setToggleEat(false);

       const eatTimeout = setTimeout(() => {
            setToggleEat(true);
        }, 2900);

        timeouts.current.push(eatTimeout);
    }

    function disableRelease() {
        setToggleRelease(false);

      const releaseTimeout = setTimeout(() => {
            setToggleRelease(true);
        }, 1500)

        timeouts.current.push(releaseTimeout); // Add timeout ID to the array
    }

    function setCommand(command, isToggled) {
        if (isToggled) {
            setMessage({command, isTouch: true});
        } 
    }

    return (
        <div className="pressable-button-container" >
            <div
                className="pressable-button"
                style={{ backgroundImage: `url(${toggleEat ? EatFoodButton : EatFoodRefillGif})` }}
                onClick={() => setCommand("eat", toggleEat)}
            />
            <div
                className="pressable-button"
                style={{ backgroundImage: `url(${toggleRelease ? ReleaseButton : ReleaseIncative})` }}
                onClick={() => setCommand("poop", toggleRelease)}
            />
        </div>
    );
});

export default PressableButton;